<template>

  <div
    class="app-news"
    role="content">

    <ul class="news-list">

      <li v-for="(item, index) in list"
        :key="index">

        <img
          v-if="item.indexPicUrl"
          alt="NEWS_POSTER"
          class="poster"
          :src="item.indexPicUrl">

        <div class="news-info">

          <span
            class="title"
            @click="check(item)">{{ item.newsTitle }}
          </span>

          <span class="date">{{ item.briefContent }}
          </span>

          <p class="brief">{{ item.publishTime }}
          </p>

        </div>

      </li>

    </ul>

    <el-pagination
      class="pager"
      :current-page="pager.current"
      :page-sizes="pager.sizes"
      :page-size="pager.size"
      :layout="pager.layout"
      :total="pager.total"
      @current-change="changePage"
      @size-change="changeSize">
    </el-pagination>

  </div>

</template>

<script>
import * as api from '@/configs/api'
export default {
  name: 'app-news',
  data () {
    return {
      list: [],
      pager: {
        current: 1,
        layout: 'total, sizes, prev, pager, next, jumper',
        size: 10,
        sizes: [10, 20, 50, 100],
        total: 3
      }
    }
  },
  mounted () {
    this.loadList()
  },
  watch: {
    $route: 'loadList'
  },
  methods: {
    calcCardStyle (poster) {
      return {
        'padding-left': `${poster ? 400 : 20}px`
      }
    },
    changePage (page) {
      this.pager.current = page
      this.loadList()
    },
    changeSize (size) {
      this.pager.size = size
      this.loadList()
    },
    check (item) {
      const name = this.$route.meta.name
      let path = ''
      switch (name) {
        case 'news':
          path = '/news-detail'
          break
        case 'notice':
          path = '/notice-detail'
          break
        case 'international':
          path = '/international-detail'
          break
        default:
          break
      }
      this.$router.push({
        path: path,
        query: {
          id: item.id,
          type: 'news'
        }
      })
    },
    loadList () {
      // const name = this.$route.meta.name
      // let type = ''
      // switch (name) {
      //   case 'news':
      //     type = '图片新闻'
      //     break
      //   case 'notice':
      //     type = '通知公告'
      //     break
      //   case 'working':
      //     type = ''
      //     break
      //   case 'international':
      //     type = '国际动态'
      //     break
      //   default:
      //     break
      // }

      const params = new FormData()
      params.append('limit', this.pager.size)
      params.append('offset', this.pager.current)
      params.append('isEn', 1)
      params.append('publishState', 1)
      params.append('newsType', '图片新闻')
      this.axios.post(api.news.page, params).then(response => {
        const res = response.data
        if (res.code === 200) {
          this.list = res.data.records.map(item => {
            if (item.indexPicUrl) {
              item.indexPicUrl = this.$baseURL + item.indexPicUrl
            }
            item.publishTime = item.publishTime.replace(/T/, ' ').replace(/\..*/, '')
            return item
          })
          this.pager.total = res.data.total
        }
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-news {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  margin: {
    bottom: 50px;
    top: 50px;
  };
  padding: {
    bottom: 50px;
    left: 23px;
    right: 15px;
    top: 20px;
  };
  text-align: left;
}

.news-list {
  flex-grow: 1;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding: {
    left: 0px;
  };

  li {
    background-color: rgba(249, 251, 255, 1);
    //box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: {
      top: 30px;
    };
    height: 180px;
    padding: {
      bottom: 20px;
      right: 20px;
      top: 20px;
    };
    position: relative;
  }
  li:first-child {
    margin: {
      top: 0px;
    };
  }
  .poster {
    //height: 100%;
    flex-shrink: 0;
    width: 300px;
  }
  .news-info {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }
  .title {
    color: black;
    cursor: pointer;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
  }
  .brief {
    flex-shrink: 0;
    line-height: 24px;
  }
  .date {
    flex-grow: 1;
    margin: {
      bottom: 14px;
      top: 14px;
    };
    // bottom: 20px;
    // position: absolute;
    // right: 20px;
  }
}

.pager {
  flex-shrink: 0;
  margin: {
    top: 30px;
  };
  text-align: center;
}
</style>
